<template>
  <v-app-bar color="primary" app>
    <v-app-bar-nav-icon class="pa-2" @click="appBarLogoClick">
      <v-img src="@/assets/img/logo-inverse.png" width="24" alt="Hi.N.E アイコン"></v-img>
    </v-app-bar-nav-icon>
    <v-toolbar-title>{{ appTitle }}</v-toolbar-title>
    <v-spacer></v-spacer>
    <template v-for="item in pageItems" :key="item.path">
      <v-btn v-if="isNarrowBreakpoint" :icon="item.icon" @click="onPageItemClick( item.path )"></v-btn>
      <v-btn v-else @click="onPageItemClick( item.path )">
        <v-icon class="mr-1">{{ item.icon }}</v-icon>{{ item.title }}
      </v-btn>
    </template>
  </v-app-bar>
</template>

<script>
  import { useDisplay } from "vuetify";
  import { useRoute } from "vue-router";
  import { getPageTitle } from '@/mixins';

  export default {
    name: 'AppHeader',
    data: () => ({
      appTitle: undefined,
      pageItems: [
        {
          path: "/",
          icon: "mdi-home-variant-outline",
          title: "Home",
        },
        {
          path: "/about",
          icon: "mdi-information-outline",
          title: "About",
        },
        {
          path: "/contents",
          icon: "mdi-apps",
          title: "Contents",
        },
      ],
    }),
    computed: {
      isNarrowBreakpoint: function(){
        return ( useDisplay().xs.value )
      }
    },
    watch: {
      '$route': function( to, from ){
        if( to.path != from.path ){
          this.appTitle = getPageTitle( to.meta.title )
          document.title = this.appTitle
        }
      }
    },
    mounted(){
      this.appTitle = getPageTitle( useRoute().meta.title )
      document.title = this.appTitle
    },
    methods: {
      updateTitle: function( title ){
        this.appTitle = getPageTitle( title )
        document.title = this.appTitle
      },
      onPageItemClick: function( path ){
        if( path != this.$router.currentRoute.path ){
          this.$router.push( path )
        }
      },
      appBarLogoClick: function(){
        this.$router.push( "/" )
      }
    },
  }
</script>
