//==========================================================
// 
// strange-window.js
// 
// (C)2011-2020 AHD / Hi.N.E
// 
// Created	: 2011/11/03
// Modified	: 2020/09/28
// 
// Written by はむっち＠ＡＨＤ
// 
//==========================================================

//==========================================================
// キャンバスラッパー

class Canvas{

  constructor( id, rectSize, webColor ){

    this.self = document.getElementById( id )
    if( !this.self || !this.self.getContext ) return false
    
    this.style = this.self.style
    this.style.backgroundColor = webColor
    
    this.self.width = rectSize.width
    this.self.height = rectSize.height
    
    this.ctx = this.self.getContext( "2d" )

    return this
  
  }

}

//==========================================================
// RectSizeクラス

class RectSize{

  constructor( width, height ){

    this.width = width
    this.height = height
  
  }

}

//==========================================================
// Pointクラス

class Point{

  constructor( x = 0, y = 0 ){

    this.x = x
    this.y = y

  }

}
//==========================================================
// AlphaColorクラス

class AlphaColor{

  constructor( red = 0, green = 0, blue = 0, alpha = 1.0 ){

    this.red = red
    this.green = green
    this.blue = blue
    this.alpha = alpha

  }

  getRgbaPropString(){
    return 'rgba(' + this.red + ', ' + this.green + ', ' + this.blue + ', ' + this.alpha + ')'
  }

}

//==========================================================
// Bubbleクラス

class Bubble{

  static get STATE(){

    return {
      GONE: 0,
      SCALE_UP: 1,
      SCALE_DOWN: 2
    }

  }

  constructor(){

    // 中心のXY座標
    this.center = new Point( 0, 0 )
    // 中心からの半径
    this.radius = 0.0
    // 円のARGBカラー値
    this.color = new AlphaColor( 0, 0, 0, 1.0 )
    // 円の状態値
    this.state = Bubble.STATE.GONE
  
  }

}

export function draw(){
    var cv = new Canvas( "canvas", new RectSize( 320, 240 ), "#e8e8e8" )
    var bubbles = new Array( 100 )
    for( var i = 0; i < bubbles.length; i++ ) bubbles[i] = new Bubble()
    
    setInterval( () => {
      cv.ctx.clearRect( 0, 0, 640, 480 )
      for( var i in bubbles ){
        updateBubble( cv, bubbles[ i ] )
      }
      cv.ctx.fillStyle = "#3333ff"
    }, 33, cv.ctx )
  
}

function updateBubble( cv, bubble ){
  switch( bubble.state ){
    // 状態別処理
    case Bubble.STATE.GONE:
      // 非表示ﾀﾞｧ───ヽ(・∀・｡)ﾉ───!!
      if( Math.floor( Math.random() * 150 ) == 1 ){
        bubble.state = Bubble.STATE.SCALE_UP
        bubble.center.x = Math.floor( Math.random() * cv.self.width )
        bubble.center.y = Math.floor( Math.random() * cv.self.height )
        bubble.color.red = Math.floor( Math.random() * 255 )
        bubble.color.green = Math.floor( Math.random() * 255 )
        bubble.color.blue = Math.floor( Math.random() * 255 )
        bubble.color.alpha = Math.random() * 0.75
      }
      break
    case Bubble.STATE.SCALE_UP:
      // 拡大ﾀﾞｧ───ヽ(・∀・｡)ﾉ───!!
      bubble.radius += 0.5
      if( bubble.radius >= 32 ){
        bubble.state = Bubble.STATE.SCALE_DOWN
      }
      break
    case Bubble.STATE.SCALE_DOWN:
      // 縮小ﾀﾞｧ───ヽ(・∀・｡)ﾉ───!!
      bubble.radius -= 0.5
      if( bubble.radius <= 0 ){
          bubble.state = Bubble.STATE.GONE
      }
      break
  }

  if( bubble.state != Bubble.STATE.GONE ){
    cv.ctx.beginPath()
    cv.ctx.fillStyle = bubble.color.getRgbaPropString()
    cv.ctx.arc( bubble.center.x, bubble.center.y, bubble.radius, 0, Math.PI * 2, false )
    cv.ctx.fill()
  }
}
