<template>
  <v-col cols="12">
    <v-breadcrumbs class="px-3 pt-2 pb-0" :items="getBreadCrumbsItems()">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
  </v-col>
</template>

<script>
  import { useRouter } from "vue-router";
  import { globalMixins } from '@/mixins';

  export default {
    name: 'BreadCrumbs',
    mixins: [
      globalMixins
    ],
    props: [
      'items',
    ],
    methods: {
      getBreadCrumbsItems: function(){
        return this.getBreadCrumbs( useRouter().options.routes, this.items );
      }
    }
  }
</script>
