const encodeRegExpLists = [
  { pattern: /&/g, replacement: "&amp;" },
  { pattern: />/g, replacement: "&gt;" },
  { pattern: /</g, replacement: "&lt;" },
  { pattern: /"/g, replacement: '&quot;' },
  { pattern: /'/g, replacement: "&#039;" },
]
const decodeRegExpLists = [
  { pattern: /&gt;/g, replacement: ">" },
  { pattern: /&lt;/g, replacement: "<" },
  { pattern: /&quot;/g, replacement: '"' },
  { pattern: /&#039;/g, replacement: "'" },
  { pattern: /&amp;/g, replacement: "&" },
]

export const encodeEntities = ( htmlText ) => {
  encodeRegExpLists.forEach( regExp => {
    htmlText = htmlText.replace( regExp.pattern, regExp.replacement )
  } )
  return htmlText
}
export const decodeEntities = ( htmlText ) => {
  decodeRegExpLists.forEach( regExp => {
    htmlText = htmlText.replace( regExp.pattern, regExp.replacement )
  } )
  return htmlText
}

export const textConvertMixins = {
  methods: {
    encodeEntities,
    decodeEntities
  }
}