export const getCategoryColor = ( category ) => {
  switch( category ){
    case 'android':
      return 'teal'
    case 'web':
      return 'light-blue'
    default:
      return 'primary'
  }
}

export const getCategoryIcon = ( category ) => {
  switch( category ){
    case 'android':
      return 'mdi-android'
    case 'web':
      return 'mdi-web'
    default:
      return 'mdi-circle'
  }
}

export const getCategorySuffix = ( category ) => {
  switch( category ){
    case 'android':
      return ' - Android アプリ'
    case 'web':
      return ' - Web サイト'
    default:
      return ''
  }
}

export const getAttachedImagePath = ( content ) => {
  if( content.image == null ){
    return '/img/noimage.png'
  }
  switch( content.category ){
    case 'android':
    case 'web':
      return '/img/' + content.image
    default:
      return '/img/noimage.png'
  }
}

export const getCategoryButtonText = ( category ) => {
  switch( category ){
    case 'android':
      return 'ダウンロード'
    case 'web':
      return 'Webサイト へ'
    default:
      return '移動する'
  }
}

export const contentsMixins = {
  methods: {
    getCategoryColor,
    getCategoryIcon,
    getCategorySuffix,
    getAttachedImagePath,
    getCategoryButtonText
  }
}