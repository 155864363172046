<template>
  <v-app>
    <app-header ref="appHeader"></app-header>
    <v-main>
      <router-view @onAppTitleUpdate="onAppTitleUpdate"></router-view>
    </v-main>
  </v-app>
</template>

<script>
import AppHeader from '@/components/AppHeader';

export default {
  name: 'App',
  components: {
    AppHeader,
  },
  data: () => ({
  }),
  methods: {
    onAppTitleUpdate: function( title ){
      this.$refs.appHeader.updateTitle( title )
    }
  }
};
</script>
