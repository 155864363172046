<template>
  <div>
    <div class="d-flex">
      <v-container class="py-0">
        <v-row>
          <bread-crumbs :items="breadCrumbs"></bread-crumbs>
        </v-row>
        <v-row>
          <v-col cols="12">
            <h3 class="text-h6 mb-4 rounded">
              プライバシーポリシー
            </h3>
            <p class="text-body-1">
              Hi.N.E（以下、「当運営」といいます）は、本サイトならびに関連するサイト、スマホアプリ（以下、「本サービスおよびアプリ」といいます）におけるプライバシー情報の取扱いについて、以下の通りプライバシーポリシー（以下、「本ポリシー」といいます）を定めます。
            </p>
            <h3 class="text-h6 mb-4 rounded">
              第１条（プライバシー情報）
            </h3>
            <p class="text-body-1">
              1. プライバシー情報のうち「個人情報」とは、個人情報保護法にいう「個人情報」を指すものとし、生存する個人に関する情報であって、当該情報に含まれるハンドルネーム、メールアドレスその他の記述等により特定の個人を識別できる情報を指します。
            </p>
            <p class="text-body-1">
              2. プライバシー情報のうち「履歴情報および特性情報」とは、上記に定める「個人情報」以外のものをいい、ご利用いただいたサービスやスマホアプリ、ご覧になったページや広告の履歴、ユーザーが検索された検索キーワード、ご利用日時、ご利用の方法、ご利用環境、郵便番号や性別、職業、年齢、ユーザーのIPアドレス、クッキー情報、位置情報、端末のカメラ機能で撮影した写真、端末内に保存されているテキスト・動画・写真・音声、端末の個体識別情報などを指します。
            </p>
            <h3 class="text-h6 mb-4 rounded">
              第２条（プライバシー情報の収集方法）
            </h3>
            <p class="text-body-1">
              1. 当運営は、ユーザーが利用登録をする際にハンドルネーム、メールアドレスなどの個人情報をお尋ねすることがあります。また、広告表示に当たって当運営の提携先（広告主、広告配信先などを含みます。以下、｢提携先｣といいます）などから収集することがあります。
            </p>
            <p class="text-body-1">
              2. 当運営は、ユーザーについて、利用したWebサービスやスマホアプリ、閲覧したページや広告の履歴、検索した検索キーワード、利用日時、利用方法、利用環境（携帯端末を通じてご利用の場合の当該端末の通信状態、利用に際しての各種設定情報なども含みます）、IPアドレス、クッキー情報、位置情報、広告ID、端末のカメラ機能で撮影した写真、端末内に保存されているテキスト・動画・写真・音声、端末の個体識別情報などの履歴情報および特性情報を、ユーザーが当運営や提携先のサービス・アプリを利用しまたはページを閲覧する際に収集することがあります。
            </p>
            <h3 class="text-h6 mb-4 rounded">
              第３条（個人情報を収集・利用する目的）
            </h3>
            <p class="text-body-1">
              当運営が個人情報を収集・利用する目的は、以下のとおりです。
            </p>
            <p class="text-body-1">
              （1）ユーザーに自分の登録情報の閲覧や修正、利用状況の閲覧を行っていただくために、ハンドルネームやメールアドレスなどの登録情報、利用されたサービスに関する情報を表示する目的
            </p>
            <p class="text-body-1">
              （2）ユーザーが当運営や提携先のサービス・アプリを利用しまたはページを閲覧する際に、端末のカメラ機能で撮影した写真、端末内に保存されているテキスト・動画・写真・音声などを、当運営や提携先のサービスへアップロードしたり、当運営や提携先のアプリ内で利用する目的
            </p>
            <p class="text-body-1">
              （3）ユーザーが本サービスおよびアプリを無料で使用するために、その代わりとしてWebページ内やアプリ内に広告を表示する目的
            </p>
            <p class="text-body-1">
              （4）ユーザーの本人確認を行うために、ハンドルネームやメールアドレスなどの情報を利用する目的
            </p>
            <p class="text-body-1">
              （5）ユーザーからのお問い合わせに対応するために、お問い合わせ内容に関する情報など当運営がユーザーに対してサービスを提供するにあたって必要となる情報や、ユーザーのサービス利用状況、メールアドレス情報などを利用する目的
            </p>
            <p class="text-body-1">
              （6）上記の利用目的に付随する目的
            </p>
            <h3 class="text-h6 mb-4 rounded">
              第４条（個人情報の第三者提供）
            </h3>
            <p class="text-body-1">
              1. 当運営は、次に掲げる場合を除いて、あらかじめユーザーの同意を得ることなく、第三者に個人情報を提供することはありません。ただし、個人情報保護法その他の法令で認められる場合を除きます。
            </p>
            <p class="text-body-1">
              （1）法令に基づく場合
            </p>
            <p class="text-body-1">
              （2）人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき
            </p>
            <p class="text-body-1">
              （3）公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき
            </p>
            <p class="text-body-1">
              （4）国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
            </p>
            <p class="text-body-1">
              （5）予め次の事項を告知あるいは公表をしている場合
            </p>
            <p class="text-body-1">
              i. 利用目的に第三者への提供を含むこと
            </p>
            <p class="text-body-1">
              ii. 第三者に提供されるデータの項目
            </p>
            <p class="text-body-1">
              iii. 第三者への提供の手段または方法
            </p>
            <p class="text-body-1">
              iv. 本人の求めに応じて個人情報の第三者への提供を停止すること
            </p>
            <h3 class="text-h6 mb-4 rounded">
              第５条（個人情報の開示）
            </h3>
            <p class="text-body-1">
              当運営は、本人から個人情報の開示を求められたときは、本人に対しこれを開示します。ただし、開示することにより次のいずれかに該当する場合は、その全部または一部を開示しないこともあり、開示しない決定をした場合には、その旨を通知します。なお、個人情報の開示に際しては、手数料の請求はいたしません。
            </p>
            <p class="text-body-1">
              （1）本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合
            </p>
            <p class="text-body-1">
              （2）当運営のサービス提供の適正な実施に著しい支障を及ぼすおそれがある場合
            </p>
            <p class="text-body-1">
              （3）その他法令に違反することとなる場合
            </p>
            <p class="text-body-1">
              前項の定めにかかわらず、履歴情報および特性情報などの個人情報以外の情報については、原則として開示いたしません。
            </p>
            <h3 class="text-h6 mb-4 rounded">
              第６条（個人情報の訂正および削除）
            </h3>
            <p class="text-body-1">
              ユーザーは、当運営の保有する自己の個人情報が誤った情報である場合には、当運営が定める手続きにより、当運営に対して個人情報の訂正または削除を求めることができます。
            </p>
            <p class="text-body-1">
              当運営は、ユーザーから前項の求めを受けてその求めに応じる必要があると判断した場合には、当該個人情報の訂正または削除を行い、これをユーザーに通知します。
            </p>
            <h3 class="text-h6 mb-4 rounded">
              第７条（個人情報の利用停止等）
            </h3>
            <p class="text-body-1">
              当運営は、本人から、個人情報が、利用目的の範囲を超えて取り扱われているという理由、または不正の手段により取得されたものであるという理由により、その利用の停止または消去（以下、「利用停止等」といいます）を求められた場合には、必要な調査を行い、その結果に基づき、個人情報の利用停止等を行い、その旨を本人に通知します。ただし、個人情報の利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって，本人の権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じます。
            </p>
            <h3 class="text-h6 mb-4 rounded">
              第８条（プライバシーポリシーの変更）
            </h3>
            <p class="text-body-1">
              本ポリシーの内容は、ユーザーに通知することなく、変更することができるものとします。
            </p>
            <p class="text-body-1">
              当運営が別途定める場合を除いて、変更後のプライバシーポリシーは、本ウェブサイトに掲載したときから効力を生じるものとします。
            </p>
            <h3 class="text-h6 mb-4 rounded">
              第９条（お問い合わせ窓口）
            </h3>
            <p class="text-body-1">
              本ポリシーに関するお問い合わせは、下記のメールアドレスまでお願いいたします。
            </p>
            <p class="text-body-1">
              メールアドレス：mail*hamuinu.com (*を@に置き換えてください)
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
  import { defineComponent } from 'vue';
  import BreadCrumbs from '@/components/BreadCrumbs';
  import AppFooter from '@/components/AppFooter';

  export default defineComponent({
    name: 'PrivacyPolicy',
    components: {
      BreadCrumbs,
      AppFooter
    },
    data: () => ({
      breadCrumbs: [
        "Home",
        "PrivacyPolicy"
      ]
    }),
  });
</script>
