<template>
  <v-footer color="primary" dark>
    {{ getCopyRights() }}
  </v-footer>
</template>

<script>
  import { globalMixins } from '@/mixins';

  export default {
    name: 'AppFooter',
    mixins: [
      globalMixins
    ],
  }
</script>
