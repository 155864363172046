import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { globalMixins } from '@/mixins'
import App from './App.vue'
import './registerServiceWorker'
import adsense from 'vue-adsense'
import router from './router'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

loadFonts()

createApp(App)
  .mixin(globalMixins)
  .component('adsense', adsense)
  .use(router)
  .use(createPinia())
  .use(vuetify)
  .mount('#app')

import "@/styles/style.less"
