<template>
  <div>
    <div class="d-flex">
      <v-container class="py-0">
        <v-row>
          <bread-crumbs :items="breadCrumbs"></bread-crumbs>
        </v-row>
        <v-row>
          <v-col cols="12" sm="7" md="8" lg="9" xl="10">
            <h3 class="text-h6 mb-4 rounded">
              A.I. - 新しい世界の、イマジネーション。
            </h3>
            <p class="text-body-1">
              新しい世界の、イマジネーションってなんだろう。
            </p>
            <p class="text-body-1">
              イマジネーションとは、想像すること。<br />
              新しい概念が当たり前になるこれからの世界で、楽しいこと、役に立つこと。<br />
              そんなことを日々考えながら、新しいものを作っていきます。
            </p>
            <h3 class="text-h6 mb-4 rounded">
              スポンサード リンク
            </h3>
            <!-- はむいぬ.com ホーム -->
            <adsense
              class="text-subtitle-1 mb-4"
              ad-client="ca-pub-7162784326630429"
              ad-slot="5041549516"
              ad-style="display: block"
              ad-format="auto"
              data-full-width-responsive="true">
            </adsense>
            <h3 class="text-h6 mb-4 rounded">
              更新やお知らせ
            </h3>
            <template v-for="(item, index) in news" :key="index">
              <template v-if="index < 3">
                <h4 class="text-subtitle-1 mb-4">
                  <v-icon>
                    mdi-calendar-month
                  </v-icon> {{ formattedDate( item.date ) }} <span v-if="isWithin1Month( item.date )" class="text-red font-weight-bold">New!</span>
                </h4>
                <p v-html="decodeEntities( item.html )" class="text-body-1">
                </p>
              </template>
            </template>
            <h3 class="text-h6 mb-4 rounded">
              ホントにフシギな「不思議な窓」。
            </h3>
            <p class="text-body-1">
              ページのすみにひっそりと置かれている、ブログパーツのような「不思議な窓」。
            </p>
            <p class="text-body-1">
              一見フラッシュのようだけれど、どうやらフラッシュじゃないみたい。
            </p>
          </v-col>
          <side-bar></side-bar>
        </v-row>
      </v-container>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
  import { defineComponent } from 'vue';
  import BreadCrumbs from '@/components/BreadCrumbs';
  import SideBar from '@/components/SideBar';
  import AppFooter from '@/components/AppFooter';
  import { textConvertMixins } from '@/mixins/TextConvert';
  import moment from 'moment';

  import news from '@/assets/data/news.json'

  export default defineComponent({
    name: 'Home',
    components: {
      BreadCrumbs,
      SideBar,
      AppFooter
    },
    mixins: [
      textConvertMixins
    ],
    data: () => ({
      breadCrumbs: [
        "Home"
      ],
      news: news.data
    }),
    methods: {
      formattedDate: function( date ){
        return moment( date ).format( 'YYYY/MM/DD' )
      },
      isWithin1Month: function( date ){
        return moment.duration( moment().diff( date ) ).asDays() < 30
      },
      combineNewsText: function( news ){
        return moment( news.date ).format( 'YYYY/MM/DD' ) + "<br />\n" + this.decodeEntities( news.html )
      }
    },
  });
</script>
